<template>
      <!-- Modal Dialog -->
      <div
        class="modal fade"
        :id="'likedByModal-' + photoId"
        tabindex="-1"
        role="dialog"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable liked-by-modal"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header border-0">
              <h5 class="modal-title">Likes</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body modal-body-likes">
              <div v-if="likes.length === 0">
                <img
                  src="https://20fx.de/photos/avatar/dummy.webp"
                  alt="avatar"
                  style="width: 9vw"
                  class="rounded-pill mt-1 me-3"
                  loading="lazy"
                />
                <span v-if="language == 'de'" class="fw-bold align-text-top"
                  >Zeige, dass dir dieses Foto gefällt!</span
                >
                <span v-else class="fw-bold align-text-top"
                  >Be the first to like this photo!</span
                >
              </div>

              <div v-for="like in likes" :key="like.lid">
                <div class="row align-items-center p-0">
                  <div class="col-auto">
                    <img
                  v-bind:src="'https://20fx.de/photos/avatar/' + like.avatar"
                  alt="avatar"
                  style="width: 9vw"
                  class="rounded-pill mt-1 mb-1 ms-0 me-0 p-0"
                  loading="lazy"
                />
                  </div>
                  <div class="col-auto fw-bold p-0">
                    <span class="align-baseline">{{
                  like.likingUser
                }}</span>
                  </div>
                  <div class="col d-inline-block text-muted text-truncate ms-2 me-2 p-0"><small>{{
                  like.description
                }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row align-items-center">

        <div class="col-auto ps-3 pe-0">
          <span
        :class="{
          'fas fa-heart liked': selfLike,
          'far fa-heart not-liked': !selfLike,
        }"
        @click="this.likePhoto(!selfLike, photoId)">
          </span>
        </div>

            <div class="col-auto ps-0 pe-0">
          <span class="likes" data-bs-toggle="modal" :data-bs-target="'#likedByModal-' + photoId">
            <strong>{{ likes.length }} {{ likes.length === 1 ? "Like" : "Likes" }}</strong>
          </span>
        </div>
      
      <div class="row">
          <div class="col-auto ps-1 pe-0 pb-1 d-inline-block text-truncate">
            <small
            class="liked-by text-muted"
            data-bs-toggle="modal"
            :data-bs-target="'#likedByModal-' + photoId">
              {{ likedBy(likes) }}
            </small>
          </div>
        </div>
      </div>
</template>
  
  <script>
  import { useVariablesStore } from "@/stores/variablesStore";

  export default {
    mounted() {
      let photoCanvas = document.getElementById("photo-" + this.photoId);
      photoCanvas.addEventListener('dblclick', () => this.onTapItem(this.photoId));
    },
    beforeUnmount() {
      let photoCanvas = document.getElementById("photo-" + this.photoId);
      photoCanvas.addEventListener('dblclick', () => this.onTapItem(this.photoId));
    },
    data() {
        return {
          selfLike: this.isSelfLiked,
        };
    },
    emits: [
    "like-event"
    ],
    computed: {
      uid() {
        return useVariablesStore().uid;
        },
        language() {
        return useVariablesStore().language;
        },
    },
    props: {
      likes: {
        type: Object,
        required: true,
      },
      photoId: {
        type: Number,
        required: true,
      },
      isSelfLiked: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      onTapItem(photoId) {
        // console.log("ontapitem: ", photoId);
      
      if (!this.selfLike) {
        this.likePhoto(true, photoId);
      } else {
        this.$toast.default("You liked the photo ❤️", {
          position: "top",
        });
      }
    },
    likePhoto(isSelfLiked, photoId) {
      // console.log("LikeItem likePhoto isSelfLiked: ", isSelfLiked);
      this.selfLike = !this.selfLike;
      this.$emit("like-event", {
            isSelfLiked: isSelfLiked,
            photoId: photoId,
          });
    },
        likedBy(likes) {
      let likedBy;
      let likedByMany = false;
      if (likes.length == 0 || likes == undefined) {
        likedBy = (this.language === 'de') ? "Zeige, dass dir dieses Foto gefällt!" : "Be the first to like this photo!";
      } else if (likes.length == 1) {
        likedBy = (this.language === 'de') ? "Gefällt " : "Liked by ";
        likedBy += likes[0].likingUser;
      } else {
        likedBy = (this.language === 'de') ? "Gefällt " : "Liked by ";
        for (let i = 0; i < likes.length; i++) {
          likedBy = likedBy + likes[i].likingUser + ", ";

          if (i === 2 && likes.length > 3) {
            likedBy = likedBy.slice(0, -2);
            likedBy = (this.language === 'de') ? likedBy + " und anderen" : likedBy + " and others";
            // likedBy = likedBy + " and others";
            likedByMany = true;
            break;
          }
        }
        if (!likedByMany) {
          likedBy = likedBy.slice(0, -2);
        }
      }
      return likedBy;
    },
    }
  };
  </script>