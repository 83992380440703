import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "vue-toast-notification/dist/theme-default.css";
import VueToast from "vue-toast-notification";
import "@fortawesome/fontawesome-free/css/all.css";
// import Vue3TouchEvents from "vue3-touch-events";
import VueSmoothScroll from 'vue3-smooth-scroll'


const app = createApp(App);
const pinia = createPinia();

app.use(VueToast);
// app.use(Vue3TouchEvents);
app.use(VueSmoothScroll)
app.use(pinia);
app.use(router);
app.mount("#app");
