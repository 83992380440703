<template>
  <div v-if="announcement" id="announcement-service" class="m-4">
    <div class="row">
      <div :class="'alert alert-' + announcement.type" role="alert">
        <span v-if="language == 'de'">{{ announcement.message.de }}</span>
        <span v-else>{{ announcement.message.en }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useVariablesStore } from "@/stores/variablesStore";

export default {
  data() {
    return {
      announcement: null,
    };
  },
  computed: {
        language() {
        return useVariablesStore().language;
        },
    },
  methods: {
    async getAnnouncement() {
      try {
        const response = await axios.get(
          "https://20fx.de/api.php?type=announcement",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.status === 200) {
          this.announcement = response.data;
        } else {
          // Handle error
          console.log("error");
        }
      } catch (error) {
        console.error("Error: ", error);
      }
    },
  },
  mounted() {
    this.getAnnouncement();
  },
};
</script>

<style>
</style>
