<template>
  <div id="photostream">
    <AnnouncementService :key="componentKey"/>
    <!-- <div v-bind:key="photo.iid" > -->
      <PhotoStreamItem
        v-for="photo in photos"
        v-bind:photoStreamItem="photo"
        v-bind:commentItem="photo.comments"
        v-bind:likesItem="photo.likes"
        :key="photo.iid" 
        @add-comment-event="addComment"
        @remove-comment-event="removeComment"
        @remove-photo-event="removePhoto"
        @like-event="likePhoto"
      />
    <!-- </div> -->
  </div>
  <div v-if="!photos" class="text-center">
    <div class="spinner-border spinner-border-sm"></div>
  </div>
</template>

<script>
import PhotoStreamItem from "./PhotoStreamItem.vue";
import PullToRefresh from "pulltorefreshjs";
import { useVariablesStore } from "@/stores/variablesStore";
import AnnouncementService from "./AnnouncementService.vue";

export default {
  data() {
    return {
      photos: [],
      metadata: [],
      maxPhotoId: null,
      maxLikeId: null,
      maxCommentId: null,
      maxPersonalLikeId: null,
      maxPersonalCommentId: null,
      newPhotos: null,
      newLikesAndComments: [],
      componentKey: 0,
      localStorage,
    };
  },
  methods: {
    async getData() {
      this.componentKey += 1;

      let path = "https://20fx.de/api.php?type=stream";
      fetch(path, {
        method: "GET",
        cache: "force-cache",
        headers: {
          "Accept-Encoding": "gzip",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.photos = data.data;
          this.metadata = data.metadata;
          this.maxPhotoId = localStorage.getItem("maxPhotoId");
          this.maxLikeId = localStorage.getItem("maxLikeId");
          this.maxCommentId = localStorage.getItem("maxCommentId");
          this.maxPersonalLikeId = localStorage.getItem("maxPersonalLikeId");
          this.maxPersonalCommentId = localStorage.getItem("maxPersonalCommentId");
          this.newPhotos = 0;
          this.newLikes = 0;
          this.newComments = 0;
          this.newPersonalLikes = 0;
          this.newPersonalComments = 0;
          this.tempMaxPersonalLikeId = this.maxPersonalLikeId;
          this.tempMaxPersonalCommentId = this.maxPersonalCommentId;

          for (let i = 0; i < this.photos.length; i++) {
            if(this.maxPhotoId < this.photos[i]['iid']) {
              this.newPhotos++;
            }

            this.photos[i].likes.forEach((like) => {
              if (like.lid > this.maxLikeId) {
                this.newLikes++;
                if (this.maxLikeId < like.lid) {
                  this.maxLikeId = like.lid;
                }
              }
            });  

            this.photos[i].comments.forEach((comment) => {
              if (comment.cid > this.maxCommentId) {
                this.newComments++;
                if (this.maxCommentId < comment.cid) {
                  this.maxCommentId = comment.cid;
                }
              }
            });  
          }

          let likedAndCommentedNotifications = [];

          for (let i = 0; i < this.photos.length; i++) {
            let likedByMessage = null;
            let commentedByMessage = null;
            let likedAndCommentedBy = {};
            let likes = [];
            let comments = [];

            if (this.photos[i].uid === this.metadata.uid) {
              likedByMessage = "Photo " + this.photos[i].iid + " liked by ";
              this.photos[i].likes.forEach((like) => {
                if (like.lid > this.maxPersonalLikeId) {
                  likes.push(like.likingUser);
                  likedByMessage = likedByMessage + like.likingUser + ", ";
                  this.newPersonalLikes++;
                  if (this.tempMaxPersonalLikeId < like.lid) {
                    this.tempMaxPersonalLikeId = like.lid;
                  }
                }
              });
              //console.log(likedByMessage.slice(0, -2));  

              commentedByMessage = "Photo " + this.photos[i].iid + " commented on by ";
              this.photos[i].comments.forEach((comment) => {
                if (comment.cid > this.maxPersonalCommentId) {
                  this.newPersonalComments++;
                  comments.push(comment.commenter);
                  commentedByMessage = commentedByMessage + comment.commenter + ", ";
                  if (this.tempMaxPersonalCommentId < comment.cid) {
                    this.tempMaxPersonalCommentId = comment.cid;
                  }
                }
              });  
              //console.log(commentedByMessage.slice(0, -2));  

              if (likes.length > 0) {
                if (!Array.isArray(likedAndCommentedBy[this.photos[i].iid])) {
                  likedAndCommentedBy = {id: this.photos[i].iid, content: this.photos[i].content};
                  //likedAndCommentedBy[this.photos[i].iid] = [];
                }
                likedAndCommentedBy['likes'] = [];
                likedAndCommentedBy['likes'].push(likes);
              }

              if (comments.length > 0) {
                // if (!Array.isArray(likedAndCommentedBy[this.photos[i].iid])) {
                //   likedAndCommentedBy[this.photos[i].iid] = [];
                // }
                likedAndCommentedBy['comments'] = [];
                likedAndCommentedBy['comments'].push(comments);
              }

              // console.log(likedAndCommentedBy);

              if (likes.length > 0 || comments.length > 0) {
                likedAndCommentedNotifications.push(likedAndCommentedBy);
                //this.newLikesAndComments.push(likedAndCommentedBy);
              }
            }
          }

          this.newLikesAndComments = likedAndCommentedNotifications;

          this.maxPersonalLikeId = this.tempMaxPersonalLikeId;
          this.maxPersonalCommentId = this.tempMaxPersonalCommentId;

          localStorage.setItem("maxPhotoId", data.data[0]['iid']);
          localStorage.setItem("maxLikeId", this.maxLikeId);
          localStorage.setItem("maxCommentId", this.maxCommentId);
          localStorage.setItem("maxPersonalLikeId", this.maxPersonalLikeId);
          localStorage.setItem("maxPersonalCommentId", this.maxPersonalCommentId);
          this.setVariables();

          if ('setAppBadge' in navigator) {
            navigator.setAppBadge(this.newPhotos);
          }
        });
    },
    setVariables() {
      const variables = {
        username: this.metadata.username,
        avatar: this.metadata.avatar,
        description: this.metadata.description,
        language: this.metadata.language,
        newPhotos: this.newPhotos,
        newLikes: this.newLikes,
        newComments: this.newComments,
        newPersonalLikes: this.newPersonalLikes,
        newPersonalComments: this.newPersonalComments,
        newLikesAndComments: this.newLikesAndComments,
      };
      useVariablesStore().setVariables(variables);
    },
    setPhotoToDelete(photoId) {
      this.photoId = photoId; // Store the photoId in data property
    },
    likePhoto(photoLikes) {
      let index;

      this.photos.forEach((photo) => {
        if (photo.iid == photoLikes.photoId) {
          var indexArray = this.photos.findIndex(function (photo) {
            return photo.iid == photoLikes.photoId;
          });
          index = indexArray;
        }
      });

      if (!photoLikes.isSelfLiked) {
        // console.log("PhotoStream schnapp");
        this.photos[index].isSelfLiked = false;

        let metadata = this.metadata;
        var indexLike = this.photos[index].likes.findIndex(function (l) {
          return l.uid == metadata.uid;
        });

        this.likesItem = this.photos[index].likes.splice(indexLike, 1);
        // this.photos.likes = this.photos[index].likes.splice(indexLike, 1);

        let toastMessage = (this.metadata.language == 'de') ? "Dir gefällt das Foto nicht 💔" : "You unliked the photo 💔";
        this.$toast.error(toastMessage, {
          position: "top",
        });
      } else {
        // console.log("PhotoStream schnipp");
        this.photos[index].isSelfLiked = true;
        this.photos[index].likes.push({
          uid: this.metadata.uid,
          likingUser: this.metadata.username,
          avatar: this.metadata.avatar,
          description: this.metadata.description
        });

        let toastMessage = (this.metadata.language == 'de') ? "Dir gefällt das Foto ❤️" : "You liked the photo ❤️";
        this.$toast.default(toastMessage, {
          position: "top",
        });
      }
    },
    addComment(comment) {
      let toastMessage = (this.metadata.language === 'de') ? "Kommentar hinzugefügt" : "Comment added";
      this.$toast.success(toastMessage, {
        position: "top",
      });

      this.photos.forEach((photo) => {
        if (photo.iid == comment.photoId) {
          comment["commenter"] = this.metadata.username;
          photo.comments.push(comment);
        }
      });
    },
    removeComment(comment) {
      let toastMessage = (this.metadata.language === 'de') ? "Kommentar gelöscht" : "Comment removed";
      this.$toast.error(toastMessage, {
        position: "top",
      });
      this.photos.forEach((photo) => {
        if (photo.iid == comment.photoId) {
          var index = this.photos.findIndex(function (photo) {
            return photo.iid == comment.photoId;
          });
          var indexComment = this.photos[index].comments.findIndex(function (
            c
          ) {
            return c.cid == comment.commentId;
          });

          this.photos.comments = this.photos[index].comments.splice(
            indexComment,
            1
          );
        }
      });
    },
    removePhoto(photoId) {
      let toastMessage = (this.metadata.language === 'de') ? "Foto gelöscht" : "Photo removed";
      this.$toast.error(toastMessage, {
        position: "top",
      });
      this.photos = this.photos.filter((photo) => photo.iid !== photoId);
    },
    handleVisibilityChange() {
      // Check if the document is visible
      if (!document.hidden) {
        // Call your method when the app becomes visible again
        this.getData();
      }
    }
  },
  created() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  mounted() {
    this.getData();
    // setInterval(this.getData, 5000);
    PullToRefresh.init({
      mainElement: "body",
      triggerElement: "#photostream",
      onRefresh: () => {
        this.getData();
      },
    });
  },
  beforeUnmount() {
    PullToRefresh.destroyAll;
  },
  unmounted() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  },
  components: { PhotoStreamItem, AnnouncementService },
};
</script>

<style>
.version {
  color: #cccccc;
}
</style>
