import { defineStore } from "pinia";

export const useVariablesStore = defineStore("variables", {
  state: () => ({
    username: "",
    avatar: "dummy.webp",
    description: "",
    language: "de",
    newPhotos: 0,
    newLikes: 0,
    newComments: 0,
    newPersonalLikes: 0,
    newPersonalComments: 0,
    newLikesAndComments: null,
  }),
  getters: {},
  actions: {
    setVariables(variables) {
      this.username = variables.username;
      this.avatar = variables.avatar;
      this.description = variables.description;
      this.language = variables.language;
      this.newPhotos = variables.newPhotos;
      this.newLikes = variables.newLikes;
      this.newComments = variables.newComments;
      this.newPersonalLikes = variables.newPersonalLikes;
      this.newPersonalComments = variables.newPersonalComments;
      this.newLikesAndComments = variables.newLikesAndComments;
    },
    // Define a mutation to update variableB
    setAvatar(avatar) {
      this.avatar = avatar;
    },
    setNewPhotos(newPhotos) {
      this.newPhotos = newPhotos;
    },
  },
});
