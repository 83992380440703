<template>
    <div class="card-title d-flex">
        <div class="flex-grow-1 text-break">
          <strong>{{ comment.commenter }}</strong>
          <span v-if="!comment.translated" class="ms-2 me-2" v-html="comment.text"></span>
          <span v-else class="ms-2 me-2" v-html="comment.translated"></span>
          <div class="d-block">
            <small v-if="language == 'de'" class="date text-nowrap">vor {{ timeAgo(comment.timestamp) }}</small> 
            <small v-else class="date text-nowrap">{{ timeAgo(comment.timestamp) }} ago</small> 
            <small v-if="language == 'de'" class="translate text-nowrap ms-2" @click="translateComment(comment)">Übersetzen</small>
            <small v-else class="translate text-nowrap ms-2" @click="translateComment(comment)">Translate</small>
          </div>
        </div>
        <button
          type="button"
          :class="{
            'comment-removable btn btn-danger btn-sm ms-1 pt-0 pb-0':
              comment.isRemovable,
            'comment-not-removable': !comment.isRemovable,
          }"
          data-bs-toggle="modal"
          :data-bs-target="'#deleteCommentModal-' + comment.cid"
        >
          <strong>&times;</strong>
        </button>

        <div
          class="modal fade"
          :id="'deleteCommentModal-' + comment.cid"
          tabindex="-1"
          role="dialog"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <h5 v-if="language == 'de'" class="modal-title">Kommentar löschen</h5>
                <h5 v-else class="modal-title">Delete comment</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <p v-if="language == 'de'">Bist du dir sicher, dass du diesen Kommentar löschen möchtest?</p>
                <p v-else>Are you sure you want to delete your comment?</p>
              </div>
              <div class="modal-footer">
                <button
                  v-if="language == 'de'" 
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Abbrechen
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  v-if="language == 'de'"
                  type="button"
                  class="btn btn-danger"
                  @click="removeComment(photoId, comment.cid)"
                  data-bs-dismiss="modal"
                >
                  Löschen
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-danger"
                  @click="removeComment(photoId, comment.cid)"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        
    </div>
  </template>
  
  <script>
import { useVariablesStore } from "@/stores/variablesStore";

  export default {
    props: {
      comment: {
        type: Object,
        required: true,
      },
      photoId: {
        type: Number,
        required: true,
      },
    },
    computed: {
        language() {
        return useVariablesStore().language;
        },
    },
    methods: {
        async performMicroAction(method, action, id, payload) {
      let path = "https://20fx.de/api.php?type=" + action + "&id=" + id;
      let payloadJson = { [action]: payload };

      const response = await fetch(path, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payloadJson),
      })
        .then((resp) => resp.json())
        .then((data) => {
          // console.log(data);
          return data;
        })
        .catch((err) => {
          console.log(err);
        });

      return response;
    },
    async translateComment(comment) {
      let request = {
        q: comment.text,
        target: this.language
      };

      const response = await this.performMicroAction("POST", "translate", null, request);
      const translatedText = await response;
      // const translatedText = data.data.translations[0].translatedText;
      // this.translatedComment = translatedText;
      //this.$set(comment, 'translated', translatedText);
      comment.translated = translatedText;
    },
    removeComment(photoId, commentId) {
      if (photoId !== null && commentId !== null) {
        this.$emit("remove-comment-event", {
              photoId: photoId,
              commentId: commentId,
            });
      }
    },
        timeAgo(timestamp) {
      let time = Date.parse(timestamp);

      var seconds = Math.floor((new Date() - time) / 1000);
      var intervalType;
      var grammarFlag = false;

      var interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        intervalType = (this.language == 'de') ? 'Jahr' : 'year';
        grammarFlag = true;
        // intervalType = "year";
      } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
          intervalType = (this.language == 'de') ? 'Monat' : 'month';
          grammarFlag = true;
          // intervalType = "month";
        } else {
          interval = Math.floor(seconds / 86400);
          if (interval >= 1) {
            intervalType = (this.language == 'de') ? 'Tag' : 'day';
            grammarFlag = true;
            // intervalType = "day";
          } else {
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
              intervalType = (this.language == 'de') ? 'Stunde' : 'hour';
              // intervalType = "hour";
            } else {
              interval = Math.floor(seconds / 60);
              if (interval >= 1) {
                intervalType = (this.language == 'de') ? 'Minute' : 'minute';
                // intervalType = "minute";
              } else {
                interval = seconds;
                intervalType = (this.language == 'de') ? 'Sekunde' : 'second';
                // intervalType = "second";
              }
            }
          }
        }
      }

      if (interval > 1 || interval === 0) {
        if (grammarFlag) {
          intervalType += (this.language == 'de') ? 'en' : 's';
        } else {
          intervalType += (this.language == 'de') ? 'n' : 's';
        }
        // intervalType += "s";
      }

      return interval + " " + intervalType;
    },
    }
  };
  </script>