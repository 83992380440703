<template>
  <div class="photos mt-2">
    <PhotoStream :key="componentKey" />
  </div>
</template>

<script>
// @ is an alias to /src
import PhotoStream from "@/components/PhotoStream.vue";
// import PullToRefresh from "pulltorefreshjs";

export default {
  data() {
    return {
      componentKey: 0,
    };
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    }
  },
  name: "PhotosView",
  components: {
    PhotoStream,
  },
  beforeUpdate() {
    // PullToRefresh.destroyAll;
  },
  updated() {
    // PullToRefresh.init({
    //   mainElement: "body",
    //   triggerElement: "#photostream",
    //   onRefresh: () => {
    //     this.forceRerender();
    //   },
    // });
  },
  mounted() {
    // PullToRefresh.init({
    //   mainElement: "body",
    //   triggerElement: "#photostream",
    //   // onRefresh() {
    //   //   //window.location.reload();
    //   //   this.forceRerender();
    //   // },
    //   onRefresh: () => {
    //     this.forceRerender();
    //   },
    // });
  },
  beforeUnmount() {
    // PullToRefresh.destroyAll;
  },
};
</script>

<style>
.photos {
  overflow: hidden;
}
</style>
