<template>
  <!-- <div class="card border-0" :key="photo.iid"> -->
  <div class="card border-0 mt-0 mb-3" :id="'photo-' + photo.iid">
    <div class="card-header bg-white border-0 p-0 m-0">
      <div class="container-fluid bg-white pt-2 ps-2 pb-0 pe-2">
        <div class="row">
          <!-- Avatar Column -->
          <div class="col-auto pe-0">
            <img
              v-bind:src="'https://20fx.de/photos/avatar/' + photo.avatar"
              alt="avatar"
              style="width: 5vh"
              class="rounded-pill"
              data-bs-toggle="modal"
              :data-bs-target="'#aboutModal-' + photo.iid"
              loading="lazy"
            />

            <!-- Modal Dialog -->
            <div
              class="modal fade"
              :id="'aboutModal-' + photo.iid"
              tabindex="-1"
              role="dialog"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header border-0">
                    <h5 class="modal-title">About {{ photo.author }}</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body text-center">
                    <img
                      v-bind:src="
                        'https://20fx.de/photos/avatar/' + photo.avatar
                      "
                      alt="avatar"
                      style="width: 50vw"
                      class="rounded-pill"
                      loading="lazy"
                    />
                    <h3 class="pt-5">{{ photo.author }}</h3>
                    <h5 class="text-muted">{{ photo.description }}</h5>
                    <h5 class="text-muted mt-5">{{ photo.photosTotal }}</h5>
                    <small class="text-muted">PHOTOS</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Username and Description Column -->
          <div class="col flex-grow-1 pe-0 text-truncate">
            <h4 class="mb-1 author">
              {{ photo.author }}
              <span v-if="language == 'de'" class="date ps-2 text-nowrap">vor {{ timeAgo(photo.timestamp) }}</span> 
              <span v-else class="date ps-2 text-nowrap">{{ timeAgo(photo.timestamp) }} ago</span> 
            </h4>
            <span class="mb-0 description">{{ photo.description }}</span>
          </div>

          <!-- X Column -->
          <div class="col-auto text-end">
            <button
              :class="{
                'btn btn-danger pt-1 pb-1 justify-content-end':
                  photo.isRemovable,
                'photo-not-removable': !photo.isRemovable,
              }"
              type="button"
              data-bs-toggle="modal"
              :data-bs-target="'#deletePhotoModal-' + photo.iid"
            >
              <strong>&times;</strong>
            </button>
          </div>

          <!-- Modal Dialog -->
          <div
            class="modal fade"
            :id="'deletePhotoModal-' + photo.iid"
            tabindex="-1"
            role="dialog"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header border-0">
                  <h5 v-if="language == 'de'" class="modal-title">Foto löschen</h5>
                  <h5 v-else class="modal-title">Delete photo</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <p v-if="language == 'de'">Bist du dir sicher, dass du dieses Foto löschen möchtest?</p>
                  <p v-else>Are you sure you want to delete your photo?</p>
                </div>
                <div class="modal-footer">
                  <button
                    v-if="language == 'de'"
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Abbrechen
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    v-if="language == 'de'"
                    type="button"
                    class="btn btn-danger"
                    @click="removePhoto(photo.iid)"
                    data-bs-dismiss="modal"
                  >
                    Löschen
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-danger"
                    @click="removePhoto(photo.iid)"
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <img v-if="!photo.isVideo"
        :id="'photo-' + photo.iid"
        v-bind:src="'https://20fx.de/photos/content/' + photo.content"
        class="card-img card-img-top"
        loading="lazy"
      />

      <video v-else controls playsinline class="card-img card-img-top" :id="'photo-' + photo.iid">
        <source v-bind:src="'https://20fx.de/photos/content/' + photo.content" type="video/mp4" />
      </video>
    </div>

    <div class="card-body p-2">
      

        <LikesItem :likes="likesItem" :photoId="photo.iid" :isSelfLiked="photo.isSelfLiked" :key="photo.iid" @like-event="likePhoto" />
      
        <CommentItem v-for="comment in commentItem" :comment="comment" :photoId="photo.iid" :key="`${comment.cid}`" @remove-comment-event="removeComment(photo.iid, comment.cid)" />

      <div class="formgroup">
        <form ref="commentForm" @submit.prevent="addComment">
          <div class="input-group">
            <!-- Modal Dialog -->
            <div
              class="modal fade"
              :id="'addCommentModal-' + photo.iid"
              tabindex="-1"
              role="dialog"
              @focus="setFocus"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header border-0">
                    <h5 v-if="language == 'de'" class="modal-title">Kommentar hinzufügen</h5>
                    <h5 v-else class="modal-title">Add comment</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="mb-3">
                      <textarea
                        v-if="language == 'de'"
                        v-model="comment"
                        ref="commentTextArea"
                        class="form-control"
                        id="comment"
                        rows="3"
                        placeholder="Schreibe etwas Nettes!"
                        required
                      ></textarea>
                      <textarea
                        v-else
                        v-model="comment"
                        ref="commentTextArea"
                        class="form-control"
                        id="comment"
                        rows="3"
                        placeholder="Add your thoughts!"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      v-if="language == 'de'"
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Abbrechen
                    </button>
                    <button
                      v-else
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      v-if="language == 'de'"
                      type="button"
                      class="btn btn-primary"
                      @touch="addComment(photo.iid)"
                      @click="addComment(photo.iid)"
                      data-bs-dismiss="modal"
                    >
                      Hinzufügen
                    </button>
                    <button
                      v-else
                      type="button"
                      class="btn btn-primary"
                      @touch="addComment(photo.iid)"
                      @click="addComment(photo.iid)"
                      data-bs-dismiss="modal"
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <input
              v-if="language == 'de'"
              type="text"
              class="form-control bg-white"
              placeholder="Schreibe etwas Nettes!"
              data-bs-toggle="modal"
              :data-bs-target="'#addCommentModal-' + photo.iid"
              readonly
            />
            <input
              v-else
              type="text"
              class="form-control bg-white"
              placeholder="Add your thoughts!"
              data-bs-toggle="modal"
              :data-bs-target="'#addCommentModal-' + photo.iid"
              readonly
            />
            <button v-if="language == 'de'" type="button" class="btn btn-secondary" disabled>
              Hinzufügen
            </button>
            <button v-else type="button" class="btn btn-secondary" disabled>
              Post
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
//import { ref } from 'vue';
import { useVariablesStore } from "@/stores/variablesStore";
import CommentItem from "@/components/CommentItem";
import LikesItem from "@/components/LikesItem";

export default {
  name: "PhotoStreamItem",
  components: {
    CommentItem,
    LikesItem,
  },
  props: [ 
    "photoStreamItem",
    "commentItem",
    "likesItem"
  ],
  emits: [
    'add-comment-event',
    'like-event',
    'remove-comment-event',
    'remove-photo-event',
  ],
  data() {
    return {
      photo: this.photoStreamItem,
      comments: this.commentItem,
      likes: this.likesItem,
      comment: "",
      translatedComment: "",
    };
  },
  computed: {
    language() {
      return useVariablesStore().language;
    },
  },
  methods: {
    async performMicroAction(method, action, id, payload) {
      let path = "https://20fx.de/api.php?type=" + action + "&id=" + id;
      let payloadJson = { [action]: payload };

      const response = await fetch(path, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payloadJson),
      })
        .then((resp) => resp.json())
        .then((data) => {
          // console.log(data);
          return data;
        })
        .catch((err) => {
          console.log(err);
        });

      return response;
    },
    async translateComment(comment) {
      let request = {
        q: comment.text,
        target: this.language
      };

      const response = await this.performMicroAction("POST", "translate", null, request);
      const translatedText = await response;
      // const translatedText = data.data.translations[0].translatedText;
      // this.translatedComment = translatedText;
      //this.$set(comment, 'translated', translatedText);
      comment.translated = translatedText;
    },
    setFocus() {
      this.$nextTick(() => {
        const commentTextArea = this.$refs.commentTextArea;
        if (commentTextArea) {
          commentTextArea.focus();
        }
      });
    },
    escapeHtml(unsafe) {
      return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    },
    // onTapItem(photoId) {
    //   // console.log("ontapitem:" , this.photo.isSelfLiked);
    //   if (!this.photo.isSelfLiked) {
    //     this.likePhoto({
    //       isSelfLiked: !this.photo.isSelfLiked,
    //       photoId: photoId.target.id.substr(6, photoId.target.id.length)}
    //     );
    //   } else {
    //     this.$toast.default("You liked the photo ❤️", {
    //       position: "top",
    //     });
    //   }
    // },
    likePhoto(data) {
      // console.log("PhotoStreamItem likePhoto isSelfLiked: ", data.isSelfLiked);
      if (!data.isSelfLiked) {
        this.performMicroAction("DELETE", "like", data.photoId, null).then(() => {
          this.$emit("like-event", {
            isSelfLiked: data.isSelfLiked,
            photoId: data.photoId,
          });
        });
      } else {
        this.performMicroAction("PUT", "like", data.photoId, null).then(() => {
          this.$emit("like-event", {
            isSelfLiked: data.isSelfLiked,
            photoId: data.photoId,
          });
        });
      }
    },
    async addComment(photoId) {
      const comment = this.comment.trim();
      if (comment !== "") {
        await this.performMicroAction("POST", "comment", photoId, comment).then(
          (response) => {
            this.$refs.commentForm.reset();
            this.comment = "";
            this.$emit("add-comment-event", {
              cid: response[0],
              photoId: photoId,
              commenter: null,
              text: this.escapeHtml(comment),
              timestamp: new Date().toISOString(),
              isRemovable: true,
            });
          }
        );
      }
    },
    removeComment(photoId, commentId) {
      if (photoId !== null && commentId !== null) {
        this.performMicroAction("DELETE", "comment", commentId, null).then(
          () => {
            this.$emit("remove-comment-event", {
              photoId: photoId,
              commentId: commentId,
            });
          }
        );
      }
    },
    removePhoto(photoId) {
      if (photoId !== null) {
        this.performMicroAction("DELETE", "photo", photoId, null).then(() => {
          this.$emit("remove-photo-event", photoId);
        });
      }
    },
    likedBy(likes) {
      let likedBy;
      let likedByMany = false;
      if (likes.length == 0 || likes == undefined) {
        likedBy = (this.language === 'de') ? "Zeige, dass dir dieses Foto gefällt!" : "Be the first to like this photo!";
      } else if (likes.length == 1) {
        likedBy = (this.language === 'de') ? "Gefällt " : "Liked by ";
        likedBy += likes[0].likingUser;
      } else {
        likedBy = (this.language === 'de') ? "Gefällt " : "Liked by ";
        for (let i = 0; i < likes.length; i++) {
          likedBy = likedBy + likes[i].likingUser + ", ";

          if (i === 2 && likes.length > 3) {
            likedBy = likedBy.slice(0, -2);
            likedBy = (this.language === 'de') ? likedBy + " und anderen" : likedBy + " and others";
            // likedBy = likedBy + " and others";
            likedByMany = true;
            break;
          }
        }
        if (!likedByMany) {
          likedBy = likedBy.slice(0, -2);
        }
      }
      return likedBy;
    },
    timeAgo(timestamp) {
      let time = Date.parse(timestamp);

      var seconds = Math.floor((new Date() - time) / 1000);
      var intervalType;
      var grammarFlag = false;

      var interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        intervalType = (this.language == 'de') ? 'Jahr' : 'year';
        grammarFlag = true;
        // intervalType = "year";
      } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
          intervalType = (this.language == 'de') ? 'Monat' : 'month';
          grammarFlag = true;
          // intervalType = "month";
        } else {
          interval = Math.floor(seconds / 86400);
          if (interval >= 1) {
            intervalType = (this.language == 'de') ? 'Tag' : 'day';
            grammarFlag = true;
            // intervalType = "day";
          } else {
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
              intervalType = (this.language == 'de') ? 'Stunde' : 'hour';
              // intervalType = "hour";
            } else {
              interval = Math.floor(seconds / 60);
              if (interval >= 1) {
                intervalType = (this.language == 'de') ? 'Minute' : 'minute';
                // intervalType = "minute";
              } else {
                interval = seconds;
                intervalType = (this.language == 'de') ? 'Sekunde' : 'second';
                // intervalType = "second";
              }
            }
          }
        }
      }

      if (interval > 1 || interval === 0) {
        if (grammarFlag) {
          intervalType += (this.language == 'de') ? 'en' : 's';
        } else {
          intervalType += (this.language == 'de') ? 'n' : 's';
        }
        // intervalType += "s";
      }

      return interval + " " + intervalType;
    },
  },
};
</script>

<style>
.card {
  margin-bottom: 5vh;
  text-align: left;
}

.date, .translate {
  color: #aaaaaa;
  font-size: 0.7em;
  font-weight: bold;
}

.card-title {
  font-weight: normal;
}

.card-text {
  font-weight: normal;
}

.likes {
  margin-left: 2vw;
  vertical-align: top;
}

.liked {
  font-size: 1.5em;
  color: rgb(255, 0, 0);
}

.not-liked {
  font-size: 1.5em;
  color: rgb(0, 0, 0);
}

.liked-by {
  margin-left: 2vw;
}

.comment-removable {
  font-size: 0.7em;
}

.comment-not-removable {
  display: none;
}

.photo-not-removable {
  display: none;
}

.author {
  font-size: 1em;
  font-weight: 700;
}

.description {
  font-size: 0.8em;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-img {
  width: 100%;
}

.modal-body-likes {
  max-height: 47vh;
  overflow-y: auto;
}
</style>
