<template>
  <div class="authorization text-start ps-3 pe-3 mt-2">
    <h1>Authorization</h1>
    <div class="input-group input-group-lg">
      <span class="input-group-text" id="inputGroup-sizing-lg">Start Code</span>
      <input
        v-model="token"
        type="text"
        :maxlength="maxTokenLength"
        class="form-control"
        aria-label="Start code input"
        aria-describedby="inputGroup-sizing-lg"
      />
    </div>
    <div class="text-center">
      <button class="btn btn-primary mt-3" @click="submitToken">Submit</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: "", // Stores the input value (5-character token)
      maxTokenLength: 5, // Maximum length of the token
    };
  },
  methods: {
    submitToken() {
      if (this.token.length === this.maxTokenLength) {
        // Send the token to the API endpoint via GET request
        this.sendTokenToAPI();
      } else {
        alert("Token must be 5 characters long.");
      }
    },
    async sendTokenToAPI() {
      try {
        const response = await fetch(
          `https://20fx.de/api.php?type=start&id=${this.token}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          // Handle the response from the API as needed
          const data = await response.json();
          this.$toast.success("Welcome back!", {
            position: "top",
          });
          localStorage.setItem("token", data);
          this.$router.push("/");
        } else {
          // Handle the API error
          this.$toast.warning("Wrong start code!", {
            position: "top",
          });
        }
      } catch (error) {
        // Handle the API error
        //console.error('Error:', error.message);
        // Handle the API error
        this.$toast.error("Wrong start code!", {
          position: "top",
        });
      }
    },
  },
};
</script>
