<template>
  <nav class="navbar navbar-expand-xxl bg-light fixed-bottom pt-2 pb-4">
    <div class="container-fluid">
      <div class="position-relative">
        <a class="navbar-brand">
          <img
            src="https://20fx.de/img/icons/apple-touch-icon.png"
            alt="Wedding"
            style="width: 9vw"
            class="rounded-pill"
            @click="showLikesAndComments()"
          />
          <div v-if="tooltipVisible" class="tooltip-container">
            <div class="tooltip">
              <div class="tooltip-content">
                <div class="row pb-2" v-for="item in newLikesAndComments" :key="item.id">
                  <div class="col-auto"><img :src="'https://20fx.de/photos/content/' + item.content" style="width: 10vw"></div>
                  <div class="col">
                    <span v-if="Array.isArray(item.likes) && item.likes.length > 0">
                      <div class="row mb-2">
                        <a :href="'#photo-' + item.id" class="link-secondary link-underline link-underline-opacity-0" v-smooth-scroll>
                        <span>{{ prettifyArray(item.likes[0]) }} 
                          <span v-if="language === 'de'">gefällt dein Foto</span>
                          <span v-else>likes your photo</span>
                        </span> 
                        </a>
                      </div>
                    </span>
                    <span v-if="Array.isArray(item.comments) && item.comments.length > 0">
                      <div class="row mb-2">
                        <a :href="'#photo-' + item.id" class="link-secondary link-underline link-underline-opacity-0" v-smooth-scroll>
                        <span>{{ prettifyArray(item.comments[0]) }}
                          <span v-if="language === 'de'">hat dein Foto kommentiert</span>
                          <span v-else>commented on your photo</span>
                        </span>
                        </a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
        <span v-if="newPersonalLikes > 0" class="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger">
          {{ newPersonalLikes }}
        </span>
        <span v-if="newPersonalComments > 0" class="position-absolute top-100 start-0 translate-middle badge rounded-pill bg-warning">
          {{ newPersonalComments }}
        </span>
      </div>
      <router-link class="nav-link" to="/">
        <i class="fa-solid fa-photo-film fa-xl position-relative"></i>
        <span v-if="newPhotos > 0" class="position-absolute translate-middle badge rounded-pill bg-primary">
          {{ newPhotos }}
        </span>
      </router-link>
      <!-- <i class="fa-solid fa-camera fa-xl"></i> -->
      <router-link class="nav-link" to="/upload"><i class="fa-solid fa-camera fa-xl"></i></router-link>
      <router-link class="nav-link" to="/agenda"
        ><i class="fa-solid fa-calendar-days fa-xl"></i
      ></router-link>
      <router-link class="nav-link" to="/menu"><i class="fa-solid fa-chair fa-xl"></i></router-link>
      <i
        v-if="updateExists"
        :value="updateExists"
        :timeout="0"
        class="fa-solid fa-wrench fa-xl"
        @click="refreshApp"
      ></i>
      <router-link class="nav-link nav-link-avatar" to="/profile"
        ><img
          v-bind:src="'https://20fx.de/photos/avatar/' + avatar"
          alt="avatar"
          style="width: 9vw"
          class="rounded-pill"
      /></router-link>
    </div>
  </nav>
  <router-view />
</template>

<script>
import { useVariablesStore } from "@/stores/variablesStore";
import update from "./mixins/update";

export default {
  computed: {
    username() {
      return useVariablesStore().username;
    },
    avatar() {
      return useVariablesStore().avatar;
    },
    description() {
      return useVariablesStore().description;
    },
    language() {
      return useVariablesStore().language;
    },
    newPhotos() {
      return useVariablesStore().newPhotos;
    },
    newLikes() {
      return useVariablesStore().newLikes;
    },
    newComments() {
      return useVariablesStore().newComments;
    },
    newPersonalLikes() {
      return useVariablesStore().newPersonalLikes;
    },
    newPersonalComments() {
      return useVariablesStore().newPersonalComments;
    },
    newLikesAndComments() {
      return useVariablesStore().newLikesAndComments;
    }
  },
  mixins: [update],
  data() {
    return {
      tooltipVisible: false,
    };
  },
  methods: {
    showLikesAndComments() {
      //console.log(this.newLikesAndComments);
      if (this.newLikesAndComments.length > 0) {
        this.tooltipVisible = !this.tooltipVisible;
      } else if (this.newLikesAndComments.length === 0) {
        this.tooltipVisible = false;
      }
      // this.$toast.default(
      //   "Made with ❤️ by Anna and Boris<br><small>Version: 2.3.8 (jerusalem)</small>",
      //   {
      //     position: "top",
      //   }
      // );
    },
    prettifyArray(items) {
      let output = "";
      for (let i = 0; i < items.length; i++) {
        output += items[i] + ", ";
      }
      return output.slice(0, -2);
    },
  },
};
</script>

<style>
html,
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #F5F5F7;
  overscroll-behavior: none;
  padding-bottom: 2vh;
  scroll-behavior: smooth;
}

.nav-link {
  /* font-weight: bold; */
  z-index: 0;
}

nav a.router-link-active.router-link-exact-active.nav-link {
  z-index: 0;
  color: #5832a8;
}

.tooltip-container {
  position: fixed;
  bottom: 7vh;
  left: 10vw;
  transform: translate(0, 100%); /* Move the tooltip out of view initially */
}

.tooltip {
  position: absolute;
  width: 300px;
  max-width: 300px; /* Define your maximum width here */
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translate(0, -100%); /* Translate the tooltip into view */
  max-height: 200px; /* Define your maximum height here */
  opacity: 1 !important;
}

.tooltip-content {
  /* Add your content styles here */
}
</style>
