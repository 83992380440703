import { createRouter, createWebHistory } from "vue-router";
import AuthorizationView from "../views/AuthorizationView.vue";
import PhotosView from "../views/PhotosView.vue";

const routes = [
  {
    path: "/auth",
    name: "auth",
    component: AuthorizationView,
    //component: () => import(/* webpackChunkName: "photos" */ '../views/AuthorizationView.vue')
    meta: { requiresAuth: false }, // Add a meta field to indicate the need for authorization
  },
  {
    path: "/",
    name: "photos",
    component: PhotosView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "photos" */ '../views/PhotosView.vue')
    meta: { requiresAuth: true }, // Add a meta field to indicate the need for authorization
  },
  {
    path: "/upload",
    name: "upload",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "upload" */ "../views/UploadView.vue"),
    meta: { requiresAuth: true }, // Add a meta field to indicate the need for authorization
  },
  {
    path: "/agenda",
    name: "agenda",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "agenda" */ "../views/AgendaView.vue"),
    meta: { requiresAuth: true }, // Add a meta field to indicate the need for authorization
  },
  {
    path: "/menu",
    name: "menu",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "agenda" */ "../views/MenuView.vue"),
    meta: { requiresAuth: true }, // Add a meta field to indicate the need for authorization
  },
  {
    path: "/profile",
    name: "profile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProfileView.vue"),
    meta: { requiresAuth: true }, // Add a meta field to indicate the need for authorization
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Simulate authentication check (replace this with your actual authentication logic)
function checkIfUserIsAuthenticated() {
  if (localStorage.getItem("token")) {
    return true;
  } else {
    return false;
  }
}

// Add a navigation guard to check authentication status
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    // Check if the user is authenticated (you can implement your own authentication check here)
    const isAuthenticated = checkIfUserIsAuthenticated();

    if (isAuthenticated) {
      // Proceed to the requested route
      next();
    } else {
      // Redirect to the authorization view
      next("/auth");
    }
  } else {
    // For routes that don't require authorization, proceed as usual
    next();
  }
});

export default router;